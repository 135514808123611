import {useContext, useState} from "react";
import AceEditor from "react-ace";
import Field from "./Field";
import { PttFieldType } from "../../types";

import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/webpack-resolver";
import { EditDataContext } from "../../contexts/EditDataContext";

type AceValidation = {
  type: string;
  text: string;
};

const Css = ({ field }: PttFieldType) => {
  const { updateStored, getValue, getLanguage } = useContext(EditDataContext);
  const language = getLanguage({ field });
  const value = getValue({ field });
  const [content, setContent] = useState<string>(value[language]);
  const [validations, setValidations] = useState<AceValidation[]>([]);

  // Store only when there are no errors
  const handleValidate = (validations: AceValidation[]) => {
    setValidations(validations);
    if (validations.filter(v => v.type === "error").length > 0) return;
    value[language] = content;
    updateStored(field, value);
  };

  return (
    <div>
      <div className="my-4">
        <Field field={field} />
      </div>
      <AceEditor
        value={content}
        mode="css"
        theme="solarized_light"
        onChange={(value) => setContent(value)}
        onValidate={handleValidate}
        width="100%"
        height="600px"
        setOptions={{
          enableLiveAutocompletion: true,
          enableSnippets: false,
          enableMobileMenu: false,
          showLineNumbers: true,
          tabSize: 2,
        }}/>
      {/* Errors */}
      {validations && validations.filter(v => v.type === "error").length > 0 && (
        <ul className="bg-red/30 p-2 text-xs border border-yellow-400">
          {validations.filter(v => v.type === "error").map((error, index) => (
            <li key={index}> Error: {error.text}</li>
          ))}
        </ul>
      )
      }
      {/* Warnings */}
      {validations && validations.filter(v => v.type === "warning").length > 0 && (
        <ul className="bg-yellow-600/50 p-2 text-xs border border-yellow-400">
          {validations.filter(v => v.type === "warning").map((warning, index) => (
            <li key={index}>Warning: {warning.text}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Css;
