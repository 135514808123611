import React, { useContext } from "react";
import LanguageSelector from "./LanguageSelector";
import { PttModelField } from "../../types";
import { GlobalContext } from "../../contexts/GlobalContext";
import { EditDataContext } from "../../contexts/EditDataContext";

type FieldProps = {
  field: PttModelField;
  children?: React.ReactNode;
  hideLabel?: boolean;
  options?: { [key: string]: unknown };
};

export const Label = ({ field }: { field: PttModelField }) => {
  const { getLiteral } = useContext(GlobalContext);
  return (
    <>
      <label
        className={`font-light uppercase text-xs select-none ${
          field.type === "checkbox" ? "" : "block"
        } ${
          ["rules", "json", "section", "css"].includes(field.type) ? "border-b border-grayLight" : ""
        }`}
        htmlFor={field.name}
      >
        {getLiteral(
          (field?.options as { [key: string]: unknown }).label as string,
          "manager_field_"
        ) || ""}
      </label>
    </>
  );
};

const Field = ({ field, children, hideLabel = false }: FieldProps) => {
  const { getErrors } = useContext(EditDataContext)
  const errors = getErrors({ field })

  return (
    <div className="relative">
      {field.type !== "model" && !hideLabel && <Label field={field} />}
      {field?.translatable ? (
        <LanguageSelector>{children}</LanguageSelector>
      ) : (
        children
      )}
      {field?.options?.info as string && !["rules", "json"].includes(field.type) && <span className="text-xs font-light">{field?.options?.info as string}</span>}
      {errors?.length > 0 && (
        <div className="text-xs font-light text-error">
          {errors.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Field;
