import React, { useContext } from "react";

import Text from "./Text";
import Color from "./Color";
import Url from "./Url";
import Password from "./Password";
import Textarea from "./Textarea";
import Select from "./Select";
import File from "./File";
import Image from "./Image";
import Number from "./Number";
import Checkbox from "./Checkbox";
import Models from "./Models";
import Date from "./Date";
import Time from "./Time";
import Datetime from "./Datetime";
import Json from "./Json";
import Model from "./Model";
import { PttModelField } from "../../types";
import { EditDataContext } from "../../contexts/EditDataContext";
import Rules from "./Rules";
import ApiKey from "./ApiKey";
import EventsList from "./EventsList";
import Section from "./Section";
import RelatedAppsInstallations from "./RelatedAppsInstallations";
import ResetPassword from "./ResetPassword";
import Installations from "./Installations";
import Css from "./Css";

type PttComponentType = {
  component: any;
  width: string;
};

const components: { [key: string]: PttComponentType } = {
  color: {
    component: Color,
    width: "2",
  },
  model: {
    component: Model,
    width: "4",
  },
  date: {
    component: Date,
    width: "2",
  },
  datetime: {
    component: Datetime,
    width: "2",
  },
  time: {
    component: Time,
    width: "2",
  },
  text: {
    component: Text,
    width: "2",
  },
  password: {
    component: Password,
    width: "4",
  },
  number: {
    component: Number,
    width: "2",
  },
  textarea: {
    component: Textarea,
    width: "4",
  },
  select: {
    component: Select,
    width: "2",
  },
  file: {
    component: File,
    width: "2",
  },
  image: {
    component: Image,
    width: "2",
  },
  checkbox: {
    component: Checkbox,
    width: "2",
  },
  models: {
    component: Models,
    width: "4",
  },
  json: {
    component: Json,
    width: "4",
  },
  url: {
    component: Url,
    width: "4",
  },
  rules: {
    component: Rules,
    width: "4",
  },
  apikey: {
    component: ApiKey,
    width: "2",
  },
  eventsList: {
    component: EventsList,
    width: "4",
  },
  section: {
    component: Section,
    width: "4",
  },
  relatedappsinstallations: {
    component: RelatedAppsInstallations,
    width: "4",
  },
  resetpassword: {
    component: ResetPassword,
    width: "2",
  },
  installations: {
    component: Installations,
    width: "4",
  },
  css: {
    component: Css,
    width: "4",
  },
};

const componentWidth = (props: { [key: string]: unknown }) => {
  if (props.name === "position") {
    return "4";
  }
  if (
    props?.options &&
    (props?.options as { [key: string]: any }).style?.width
  ) {
    return (props?.options as { [key: string]: any }).style.width;
  }
  return components[props.type as string].width;
};

const componentClassName = (props: { [key: string]: unknown }) => {
  const width = componentWidth(props);

  let className = `col-span-4 md:col-span-${width}`;
  if (props.type === "hidden") {
    return "hidden";
  }
  return className;
};

const componentInnerClassName = (props: { [key: string]: unknown }) => {
  let className = "";
  if (props.name === "position") {
    className += "md:w-1/2 md:pr-2";
  }
  return className;
};

type WildcardType = {
  field: PttModelField;
};

const Wildcard = ({ field }: WildcardType) => {
  const { storedData } = useContext(EditDataContext);

  if (field.type === "hidden") return null;

  if (!components[field.type as string]) {
    return <>Field {field.type} not found</>;
  }

  // If field type css and app is not ScentBot, do not show field
  if (field.type === "css" && storedData) {
    const isScentBotWidget = (storedData.widgets as { [key: string]: any })[field.parentField?.index as string]?.widget === "669fc47f1e5bc46cce4d3eb2";
    if (!isScentBotWidget) return null
  }

  if (field?.showIf && storedData) {
    const showIf: { [key: string]: unknown } = field.showIf as {
      [key: string]: unknown;
    };
    let valid = false;
    Object.keys(showIf).forEach((key) => {
      if (storedData[key] === showIf[key]) {
        valid = true;
      }
    });
    if (!valid) {
      return null;
    }
  }

  const TagName = components[field.type as string].component;

  return (
    <div className={componentClassName(field)}>
      <div className={componentInnerClassName(field)}>
        <TagName field={field} />
      </div>
    </div>
  );
};

export default Wildcard;
